















































import {
  computed,
  defineComponent, useContext, useFetch,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import { APP_ROUTER_PATHS } from '~/constants';
import { useUser } from '~/modules/customer/composables/useUser';
import userGetters from '~/modules/customer/getters/userGetters';

export default defineComponent({
  name: 'MyAccount',

  components: {
    SvgImage,
  },

  setup() {
    const {
      user, load: loadUser, logout, isAuthenticated,
    } = useUser();
    const { i18n } = useContext();

    useFetch(async () => {
      if (!user.value?.email) {
        await loadUser({ customQuery: { customer: 'customer' } });
      }
    });

    const handlers = {
      'log-out': logout,
    };

    const getHandler = (e, id: string) => {
      e?.preventDefault();
      handlers[id]?.();
    };
    const clickHandler = (e: Event) => {
      if (isAuthenticated.value) {
        e.preventDefault();
      }
    };

    const firstName = computed(() => (isAuthenticated.value
      ? userGetters.getFullName(user.value)
      : i18n.t('My account')));

    return {
      firstName,
      isAuthenticated,
      accountPath: APP_ROUTER_PATHS.account,
      logout,
      getHandler,
      clickHandler,
    };
  },

  data() {
    return {
      routes: APP_ROUTER_PATHS,
      links: [
        {
          path: APP_ROUTER_PATHS.account,
          isExternal: true,
          value: 'My account',
        },
        {
          path: APP_ROUTER_PATHS.orders,
          value: 'My orders',
          isExternal: true,
        },
        {
          path: APP_ROUTER_PATHS.savedPrinters,
          value: 'My saved printers',
          isExternal: true,
        },
        {
          path: APP_ROUTER_PATHS.speedyReorder,
          value: 'SpeedyReorder',
          isExternal: true,
        },
        {
          path: APP_ROUTER_PATHS.rmaReturns,
          value: 'My returns',
          isExternal: true,
        },
        {
          path: APP_ROUTER_PATHS.logout,
          value: 'Sign Out',
          isExternal: true,
          id: 'log-out',
        },
      ],
    };
  },
});
