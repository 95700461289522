import { DYNAMIC_CATEGORIES } from '~/components/ProductsListing/config';
import {
  FilterParams, nonFilters, Params, Products, QueryParams, reduceFilters,
} from '~/composables';
import { PRINTERS_CATEGORY_UID, PRINTERS_PAGE_SIZE } from '~/constants';
import { cmsPageQueryString } from '~/customQueries/magento';
import categoryMetaGql from '~/modules/catalog/category/composables/useCategory/categoryMeta.gql';
import productListingGql from '~/modules/catalog/category/composables/useCategory/productListing.gql';
import getFacetDataQuery from '~/modules/catalog/category/composables/useFacet/getFacetData.gql';
import getSearchDataQuery from '~/modules/catalog/category/composables/useFacet/getSearchData.gql';
import { createProductAttributeFilterInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeFilterInput';
import { createProductAttributeSortInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeSortInput';
import { perPageOptions } from '~/modules/catalog/category/composables/useFacet/perPageOptions';
import { sortingOptions } from '~/modules/catalog/category/composables/useFacet/sortingOptions';

export enum PageEntityTypeEnum {
  Category = 'CATEGORY',
  CategoryProducts = 'PRODUCTS',
  CmsPage = 'CMS_PAGE',
  Product = 'PRODUCT',
}

interface ContextRouteInterface {
  path?: string;
  name?: string;
  query?: QueryParams;
}

export const getFiltersDataFromUrl = (query: QueryParams, onlyFilters = false): FilterParams => {
  const currentQuery = query;
  return (
    Object.keys(currentQuery)
      .filter((f) => (onlyFilters ? !nonFilters.has(f) : f))
      // eslint-disable-next-line unicorn/prefer-object-from-entries
      .reduce(reduceFilters(currentQuery), {})
  );
};

export const getFacetsFromURL = (query: QueryParams): Params => {
  const currentQuery = query;

  return {
    filters: getFiltersDataFromUrl(query, true),
    itemsPerPage: Number.parseInt(currentQuery.itemsPerPage, 10) || 10,
    page: Number.parseInt(currentQuery.page, 10) || 1,
    sort: currentQuery.sort ?? '',
    term: currentQuery.term || currentQuery.q,
  };
};

export const getDynamicCategory = (path) => {
  const categories = Object.values(DYNAMIC_CATEGORIES).flat() || [];

  return categories.find((item) => item.url_path.toLowerCase() === path.toLowerCase().slice(1));
};

const PAGE_TYPE_CONFIG = {
  [PageEntityTypeEnum.Product]: [
    {
      queryName: 'productDetails',
      getParams: (routeData) => ([
        {
          filter: {
            sku: {
              eq: routeData.sku,
            },
          },
        },
        { productDetails: 'productDetails' },
      ]),
      stateKey: 'product',
      resultFn: 'products.items[0]',
    },
  ],
  [PageEntityTypeEnum.Category]: [
    {
      queryName: 'customQuery',
      getParams: (routeData) => ([
        {
          query: productListingGql,
          queryVariables: {
            filters: {
              category_uid: {
                eq: routeData.uid,
              },
            },
          },
        },
      ]),
      stateKey: 'category',
      resultFn: 'categories.items[0]',
    },
    {
      queryName: 'customQuery',
      getParams: (routeData) => ([
        {
          query: categoryMetaGql,
          queryVariables: {
            filters: {
              category_uid: {
                eq: routeData.uid,
              },
            },
          },
        },
      ]),
      stateKey: 'category',
      resultFn: 'categories.items[0]',
    },
  ],
  [PageEntityTypeEnum.CategoryProducts]: [
    {
      queryName: 'customQuery',
      getParams: (routeData, route: ContextRouteInterface) => {
        const dynamicCategory = getDynamicCategory(route.path);
        const params = {
          ...getFacetsFromURL(route.query),
          ...(route.name !== 'search' ? { category_uid: routeData.uid } : {}),
          ...(dynamicCategory ? { dynamicCategory } : {}),
        };

        return [
          {
            query: route.name !== 'search' ? getFacetDataQuery : getSearchDataQuery,
            queryVariables: {
              pageSize: PRINTERS_PAGE_SIZE,
              search: params.term ? params.term : '',
              currentPage: params.page,
              filter: createProductAttributeFilterInput(params),
              sort: createProductAttributeSortInput(params.sort || ''),
            },
          },
        ];
      },
      stateKey: 'initialData',
      resultFn: 'products',
    },
  ],
  [PageEntityTypeEnum.CmsPage]: [
    {
      queryName: 'customQuery',
      getParams: (routeData) => ([
        {
          query: cmsPageQueryString,
          queryVariables: {
            identifier: routeData.identifier,
          },
        },
      ]),
      stateKey: 'cmsPage',
      resultFn: 'cmsPage',
    },
  ],
};

export const getConfigByPageType = (type: string) => PAGE_TYPE_CONFIG[type];

export const getProductsData = (data: Products, route: ContextRouteInterface, routeData) => {
  const dynamicCategory = getDynamicCategory(route.path);

  return {
    data: {
      items: data?.items ?? [],
      aggregations: data?.aggregations ?? [],
      total: data?.total_count,
      availableSortingOptions: sortingOptions,
      perPageOptions,
      itemsPerPage: PRINTERS_PAGE_SIZE,
    },
    input: {
      ...getFacetsFromURL(route.query),
      ...(routeData?.uid === PRINTERS_CATEGORY_UID ? { category_uid: routeData.uid } : {}),
      ...(dynamicCategory ? { dynamicCategory } : {}),
    },
  };
};

export const fetchData = async (context, pageStore, config) => {
  const {
    queryName, getParams,
  } = config;

  const { data, errors } = await context.app.$vsf.$magento.api[queryName](...getParams(pageStore.routeData, context.route));

  return {
    data,
    errors,
  };
};
