























import {
  computed,
  defineComponent, onMounted, useContext, useRouter,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import { APP_ROUTER_PATHS } from '~/constants';
import { useCart } from '~/modules/checkout/composables/useCart';

export default defineComponent({
  name: 'CartIcon',

  components: {
    SvgImage,
  },

  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const router = useRouter();
    const { localePath } = useContext();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();

    const goToCart = async () => {
      await router.push(localePath(APP_ROUTER_PATHS.checkout));
    };

    onMounted(async () => {
      if (!props.isMobile && cart.value) {
        await loadCartTotalQty();
      }
    });

    return {
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      goToCart,
    };
  },
});
